<template>
  <div
    class="visit-list-item"
    :style="`height: ${type === 'meeting' ? '140px' : '130px'}`"
    @click="$router.push(`work-create?id=${id}&type=${type}`)"
  >
    <div class="modify" :style="`background: ${color}`"></div>
    <div style="width: 99%">
      <div class="item-main">
        <!-- <img src="./../../../assets/lundbeck/portrait.png" class="avatar" /> -->
        <div class="custom-item-right">
          <div class="header clearfix">
            <div class="name" style="flex: 1">
              <span class="name-w" style="font-size: 18px;" v-if="type === 'meeting'">{{
                item.meeting_subject
              }}</span>
              <span class="name-w" v-if="type === 'training'">{{
                item.training_subject
              }}</span>
            </div>
          </div>
          <p class="department">
            <!-- {{ item.ta }} -->
            {{ type === 'meeting' ? item.meeting_type : item.training_channel }}
          </p>
          <p class="department">{{ item.product }}</p>
          <div class="address">
            <div class="hospital">
              <span class="e-ellipsis-l1">
                {{ item.institution_name }}
              </span>
            </div>
          </div>
          <div class="address">
            <div class="hospital">
              <span v-if="item.meeting_date" class="e-ellipsis-l1">
                {{ item.meeting_date.substring(0, 10) }}
              </span>
              <span v-if="item.training_date" class="e-ellipsis-l1">
                {{ item.training_date.substring(0, 10) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="isSub"
        class="item-sub"
        style="
          padding-right: 10px;
          height: 50px;
          overflow: hidden;
          text-overflow: ellipsis;
        "
      >
        <div style="margin-bottom: 10px; color: #8bc3ee">
          {{ item.insight_question?.question }}
        </div>
        <div class="max-line-2">
          {{ item.insight_content }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'VisitListItem',
  props: ['color', 'isSub', 'item', 'type'],
  data() {
    return {};
  },
  computed: {
    id() {
      return this.type === 'meeting'
        ? this.item.medical_meeting_id
        : this.item.medical_training_id;
    },
  },
  created() {},
  mounted() {},
  destroyed() {},
  methods: {},
};
</script>
<style scoped lang="scss">
@import '@/styles/var.scss';
.visit-list-item {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 6px;
  margin-bottom: 20px;
  .modify {
    width: 1%;
    height: 100%;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .item-main {
    margin-left: 20px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 30px;
    }
    .custom-item-right {
      flex: 1;
      font-size: 12px;
      text-align: left;
      padding-left: 10px;
      @include suggest-detail-header();
      .count {
        // float: right;
        font-size: 19px;
        color: #b2b2b2;
        .count-color {
          color: #3caa45;
          min-width: 32px;
        }
      }
      .department {
        padding: 6px 0 4px 0;
      }
      .address {
        color: #b2b2b2;
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        .hospital {
          flex: 1;
          min-width: 0;
          width: 0;
          line-height: 20px;
          span {
            display: block;
          }
        }
        .cube-btn {
          // width: 84px;
          padding: 4px 8px;
          font-size: 11px;
          border-radius: 20px;
        }
      }
    }
  }
  .item-sub {
    font-size: 12px;
    text-align: left;
    margin-left: 25px;
    margin-top: 10px;
  }
}
</style>
